import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Bewoner from '../components/bewoner'

export default ({ data }) => {
  const bewoners = data.allBewonersYaml.edges.map(edge => edge.node)
  return (
    <Layout>
      {bewoners.sort((a, b) => a.van - b.van).map(bewoner => (
        <Bewoner
          key={bewoner.id}
          image={bewoner.image}
          name={bewoner.name}
          rang={bewoner.rang}
          geboortedatum={bewoner.geboortedatum}
          studie={bewoner.studie}
          jaarclub={bewoner.jaarclub}
          kamer={bewoner.kamer}
          since={bewoner.van}
        />
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BewonersPage {
    allBewonersYaml(filter: { tot: { eq: null } }) {
      edges {
        node {
          id
          name: title
          image
          rang
          geboortedatum
          studie
          jaarclub
          kamer
          van
        }
      }
    }
  }
`
