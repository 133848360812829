import React from 'react'
import { rhythm } from '../typography'

export default ({
  image,
  name,
  rang,
  geboortedatum,
  studie,
  jaarclub,
  kamer,
  since,
}) => (
  <div
    css={{
      clear: 'both',
      marginBottom: rhythm(2),
      textAlign: 'center',

      '@media (min-width: 600px)': {
        textAlign: 'left',
        img: {
          float: 'left',
          marginRight: rhythm(1),
        },
      },
    }}
  >
    <img
      src={image}
      css={{
        width: 150,
        maxWidth: '100%',
        marginBottom: rhythm(0.5),
      }}
      alt=""
    />
    <div>
      <h4 css={{ marginBottom: rhythm(0.25), fontSize: rhythm(0.75) }}>
        {name}
        <span
          css={{
            marginTop: rhythm(0.125),
            display: 'block',
            color: 'gray',
            fontStyle: 'italic',
            fontWeight: 'normal',
            fontSize: rhythm(0.65),
          }}
        >
          {rang}
        </span>
      </h4>
      <ul
        css={{
          margin: 0,
          padding: 0,
          listStyleType: 'none',
          li: { margin: 0 },
        }}
      >
        <li>{geboortedatum}</li>
        <li>{studie}</li>
        <li>{jaarclub}</li>
        <li>
          Kamer {kamer}, sinds {since}
        </li>
      </ul>
    </div>
  </div>
)
